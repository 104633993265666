import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Markdown from "../components/markdown"

const IndexPage = ({ data }) => {
  let html = data.markdownRemark.html
  html = html.replace(/<h1.*h1>/, "<h1>Hi,</h1>")
  html = html.replace(/^<p><a.*\/a><\/p>$/gm, "")
  html = html.replace(/<a href="[a-zA-z:0-9/%#-]+">([a-zA-z ]+)<\/a>/g, "$1")
  return (
    <Layout showNav={false}>
      <Markdown className="max-w-xl markdown" html={html} />
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/Portfolio/Intro/" } }) {
      fields {
        slug
      }
      html
    }
  }
`
